<template>
    <v-img 
      :src="require('@/assets/cover.jpg')"
      :lazy-src="`https://picsum.photos/10/6?image=15`"
      class="hidden-sm-and-down grey lighten-2"
      :min-height="'calc(50vh - ' + $vuetify.application.top + 'px)'"
      :gradient="$vuetify.theme.dark ?'rgba(0, 0, 0, .10), rgba(0, 0, 0, .10)':undefined"
    
    >
    <template v-slot:placeholder>
              <v-row class="fill-height ma-0" align="center" justify="center">
                <v-progress-circular
                  indeterminate
                  color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
      <v-row
        class="fill-height"
        align="center"
      >
    <v-col  md="12">
  </v-col>
  
        <v-col
         
          md="6"
          offset-md="3"
        >

          <v-btn
          color="primary"
            depressed
              href="#contact"
                 @click="onClick($event, link)"
          >
          اشتراك
          </v-btn>
        </v-col>
      </v-row>
    </v-img>
</template>

<script>
  export default {
    name: 'HomeBanner',
         methods: {
   
    onClick(e, item) {
      e.stopPropagation();
      if (item.to || !item.href) return;
      this.$vuetify.goTo(item.href.endsWith("!") ? 0 : item.href);
    },
  },
  }
</script>
